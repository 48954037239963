<template>
  <div class="dropdown" ref="dropdown">
    <div class="dropdown__header" @click="expand">
      <slot name="dropdownHeader"></slot>
      <span v-if="error" class="has-error animated headShake">{{ error }}</span>
    </div>
    <transition name="slide-fade">
      <div
          v-if="visible"
          class="dropdown__nav"
          :class="chevron + ' ' + position + ' ' + align"
          :style="{'--margin': margin, '--width': width, '--height': height}"
      >
        <slot name="dropdownHeadFixed"></slot>
        <div class="dropdown__body ez-slim-scroll">
          <slot name="dropdownBody"></slot>
        </div>
        <div class="dropdown__footer">
          <slot name="dropdownFooter"></slot>
        </div>
        <ul v-if="type === 'list'">
          <li v-for="item in data" :key="item" @click="select(item, $event)">
            <span>{{ item }}</span>
          </li>
        </ul>
        <ul v-else>
          <li v-for="item in data" :key="item" @click="select(item)">
            <i>{{ item.icon }}</i>
            <span>{{ item.text }}</span>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import {bus} from "@/helpers/bus";

export default {
  name: "Dropdown",
  props: {
    position: {
      type: String,
      enum: ["top", "right", "bottom", "left"],
      default: "top"
    },
    align: {
      type: String,
      enum: ["al-start", "al-center", "al-end"],
      default: "al-start"
    },
    type: {type: String, enum: ["list", "icon-list"], default: "list"},
    data: {type: Array, default: () => []},
    chevron: {
      type: String,
      enum: ["top-start", "top-end", "bottom-start", "bottom-end", "none"],
      default: "none"
    },
    onExpand: Function,
    margin: String,
    height: String,
    width: String,
    onSelectOption: Function,
    error: {type: String},
  },
  data() {
    return {
      visible: false
    };
  },

  methods: {
    select(item) {
      if (this.onSelectOption) this.onSelectOption(item);
    },

    expand() {
      this.visible = !this.visible;

      if (this.visible && this.onExpand) {
        this.onExpand();
      }
    },

    documentClick(e) {
      let el = this.$refs.dropdown;
      let target = e.target;

      if (el !== target && el && !el.contains(target)) {
        this.visible = false;
      }
    }
  },

  created() {
    document.addEventListener("click", this.documentClick);

    bus.$on("HIDE_ALL_MODALS", () => {
      this.visible = false;
    });
  },

  destroyed() {
    document.removeEventListener("click", this.documentClick);

    //bus.$off('HIDE_ALL_MODALS');
  }
};
</script>

<style scoped lang="scss">

$margin: "15px";

.dropdown {
  --height: 550px;
  --width: auto;

  position: relative;
  min-width: 20px;
  min-height: 20px;
  display: inline-block;

  &__header {
    position: relative;

    .has-error {
      position: absolute;
      bottom: -14px;
      font-size: .7rem;
      right: 5px;
      color: color(bg-red);
      font-weight: $font-medium;
    }

    @keyframes bounce {
      from,
      20%,
      53%,
      80%,
      to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }

      40%,
      43% {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0);
      }

      70% {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
      }

      90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0);
      }
    }

    .bounce {
      -webkit-animation-name: bounce;
      animation-name: bounce;
      -webkit-transform-origin: center bottom;
      transform-origin: center bottom;
    }
  }

  &__nav {
    background: white;
    border: 1px solid #e4e8f0;
    box-shadow: 0 0 12px rgba(153, 155, 168, 0.15);
    border-radius: .1875rem;
    //max-width: 360px;
    max-height: var(--height);
    position: absolute;
    display: flex;
    flex-direction: column;
    --margin: #{$margin};
    width: var(--width);
    z-index: 99999;

    .dropdown__body {
      flex: 1;
      overflow: auto;
    }

    &.top-start,
    &.top-end,
    &.bottom-start,
    &.bottom-end {
      &:before {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        border: 1px solid color(border);
        border-right: none;
        border-bottom: none;
        border-top-left-radius: .1875rem;

        background: white;
      }
    }

    &.top-start {
      &:before {
        transform: rotate(45deg);
        left: 10px;
        top: -5.5px;
      }
    }

    &.top-end {
      &:before {
        transform: rotate(45deg);
        right: 10px;
        top: -5.5px;
      }
    }

    &.bottom-start {
      &:before {
        transform: rotate(-135deg);
        left: 10px;
        bottom: -5.5px;
      }
    }

    &.bottom-end {
      &:before {
        transform: rotate(-135deg);
        right: 10px;
        bottom: -5.5px;
      }
    }

    ul {
      list-style: none;
      width: 100%;

      li {
        padding: 5px 10px;
        position: relative;
        z-index: 9999;
        min-width: 100px;
        white-space: nowrap;
        cursor: pointer;
      }
    }

    .chevron {
      width: 20px;
      height: 20px;
    }

    &.top {
      bottom: 100%;
      margin-bottom: var(--margin);

      &.al-start {
        left: 0;
      }

      &.al-end {
        right: 0;
      }
    }

    &.right {
      left: 100%;
      margin-left: var(--margin);

      &.al-start {
        top: 0;
      }

      &.al-end {
        bottom: 0;
      }
    }

    &.bottom {
      top: 100%;
      margin-top: var(--margin);

      &.al-start {
        left: 0;
      }

      &.al-end {
        right: 0;
      }
    }

    &.left {
      right: 100%;
      margin-right: var(--margin);

      &.al-start {
        top: 0;
      }

      &.al-end {
        bottom: 0;
      }
    }
  }
}
</style>
